import React from 'react';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faUser} from "@fortawesome/free-solid-svg-icons";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons";
import {faEdit} from "@fortawesome/free-solid-svg-icons";
import { Container, Row, Col } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';

class QuotePart extends React.Component {

  formHandle = event => {
    toast.success("Thank you for submite quote!");
    event.preventDefault();
  }

  render() { 
    const {t} = this.props
    return ( 
      <div className="quote-area bg_cover"
        style={{ backgroundImage: "url(/assets/images/form-bg.jpg)" }}
      >
        <div className="quote-overlay">
          <Container>
            <Row className="justify-content-center">
              <Col lg="6">
                <div className="section-title-2 text-center pl-25 pr-25">
                  <h2 className="title">{t('quote-part')}</h2>
                  <p>
                  {t('quote-part.desc')}
                  </p>
                </div>
                {/* section title 2 */}
              </Col>
            </Row>
            {/* row */}
            <div className="quote-form">
            <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSe5e8rHW3XlqRtnt9ybUXV1VfvVwUUXL8wGAhRu8Hl6uZL3LQ/viewform?embedded=true" width="640" 
            height="1201" frameborder="0" marginheight="0" marginwidth="0">Đang tải…</iframe>
            </div>
            {/* quote form */}
          </Container>
          {/* container */}
        </div>
      </div>
    );
  }
}
 
export default withTranslation('translation')(QuotePart);