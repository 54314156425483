import React, {useState} from "react";
import i18n from '../../i18n';
import jp_flag from '../../assets/images/flags/jp1.png'
import uk_flag from '../../assets/images/flags/uk1.png'
import vn_flag from '../../assets/images/flags/vn1.png'

const LanguageSelector = () => {
    
    const [setSelectedLanguage] = useState(i18n.language); // i18n.language contains the language assigned to lng in i18n.js file.
    const chooseLanguage = (e) => {
        i18n.changeLanguage(e);   
        localStorage.setItem('lang',e)
        try{
            setSelectedLanguage(e);
        } catch (e) {
            console.log(e)
        }
    }
//onClick={setSelectedLanguage('jp')}
    return (
        <div className="language-selector">
            {/*<a href="#" value='jp' onClick={() => chooseLanguage('jp')}><img src={jp_flag}/></a>   */}
            <a href="#" value='vi' onClick={() => chooseLanguage('vi')}><img src={vn_flag}  /></a>   
            <a href="#" value='en' onClick={() => chooseLanguage('en')}><img src={uk_flag} /></a>   
        </div>
    );
};

export default LanguageSelector;