import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faTwitter,
    faLinkedinIn,
    faYoutube
} from '@fortawesome/free-brands-svg-icons';


class SocialLink extends Component {
    render() { 
        return ( 
            <ul className='social-link'>
                <li>
                    <a href="https://twitter.com/BsonTech" target="__blank">
                        <FontAwesomeIcon icon={faTwitter}/>
                    </a>
                </li>
                <li>
                    <a href="https://www.linkedin.com/company/bson-tech/" target="__blank">
                        <FontAwesomeIcon icon={faLinkedinIn}/>

                    </a>
                </li>
            </ul>
         );
    }
}
 
export default SocialLink;