import React from 'react';
import ModalVideo from 'react-modal-video'
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import Layout from '../../layouts';
import { Container, Row, Col } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';

class ContactPage extends React.Component {

  constructor () {
    super()
    this.state = {
      isOpen: false
    }
    this.openModal = this.openModal.bind(this)
  }

  // componentDidMount() {
	// 	setTimeout(function () {
	// 		document.querySelector(".preloader").style = "display: none";
	// 	}, 5000);
  // }
  openModal = event => {
    this.setState({isOpen: true});
    event.preventDefault();
  }

  formHandle = event => {
    toast.success("Thank you for contact with us!");
    event.preventDefault();
  }

    render() { 
      const {t} = this.props
        return ( 
          <Layout>
        <div className="page-title-area">
          <Container>
            <Row>
              <Col lg="12">
                <div className="page-title-item text-center">
                  <h2 className="title">{t('contact-us')}</h2>
                  <nav aria-label="breadcrumb">

                  
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link  to={"/"}>{t('home')} </Link>
                      </li>
                      <li className="breadcrumb-item active" aria-current="page">
                      {t('contact-us')}
                      </li>
                    </ol>
                  </nav>
                </div>
                {/* page title */}
              </Col>
            </Row>
            {/* row */}
          </Container>
          {/* container */}
        </div>
        {
          /*====== PAGE TITLE PART ENDS ======*/
        }
        <div>
          {/*====== CONTACT DETAILS PART START ======*/}
          <div className="contact-details-area pt-90 pb-120">
            <Container>
              <Row>
                <Col lg="6">
                  <div className="contact-info mr-30 mt-30">
                    <div className="contact-item-1">
                      <div className="contact-info-item text-center">
                        <i className="fal fa-phone" />
                        <h5 className="title">{t('contact-us.phone')}</h5>
                        <p>+7 (800) 123 45 69</p>
                      </div>
                      <div className="contact-info-item text-center">
                        <i className="fal fa-envelope" />
                        <h5 className="title">{t('contact-us.email')}</h5>
                        <p>info@webmail.com</p>
                      </div>
                    </div>
                    <div className="contact-item-1">
                      <div className="contact-info-item text-center">
                        <i className="fal fa-map" />
                        <h5 className="title">{t('contact-us.location')}</h5>
                        <p>{t('contact-us.location-address')}</p>
                      </div>
                      <div className="contact-info-item text-center">
                        <i className="fal fa-globe" />
                        <h5 className="title">{t('contact-us.social')}</h5>
                        <p>x.com/BsonTech</p>
                      </div>
                    </div>
                  </div>
                  {/* contact info */}
                </Col>
                <Col lg="6">
                  <div className="map-area mt-30">
         

                    <iframe
                      src="//www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d2771.0156285073654!2d106.76422897744777!3d10.826009472281044!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTDCsDQ5JzMyLjciTiAxMDbCsDQ1JzUyLjYiRQ!5e0!3m2!1svi!2s!4v1691479100896!5m2!1svi!2s"
                      style={{ border: 0 }}
                      allowFullScreen
                      width={600}
                      height={450}
                      title="Our Localtion"
                    />
                  </div>
                  {/* map area */}
                </Col>
              </Row>
              {/* row */}
            </Container>
            {/* container */}
          </div>
          {/*====== CONTACT DETAILS PART ENDS ======*/}
          {/*====== GET IN TOUCH PART START ======*/}
          <div className="get-in-touch-area get-in-touch-area-2">
            <Container>
              <Row>
                <Col lg="12">                  
                  {/* section title */}
                  <div className="form-area">
                  <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSe5e8rHW3XlqRtnt9ybUXV1VfvVwUUXL8wGAhRu8Hl6uZL3LQ/viewform?embedded=true" width="640" 
            height="1201" frameborder="0" marginheight="0" marginwidth="0">Đang tải…</iframe>
                    <p className="form-message" />
                  </div>
                </Col>
                
              </Row>
            </Container>
          </div>
          {/*====== GET IN TOUCH PART ENDS ======*/}
        </div>
        </Layout>
      );
    }
}
 
export default withTranslation ('translation')(ContactPage);