import React from 'react';
import { Link } from "react-router-dom";
import { Nav } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';

class PartialNav extends React.Component {
    render() { 
      const {t} = this.props
        return ( 
            <>
            <Nav className="mr-auto">
              <li className="nav-item">
              <Link className="nav-link" to={'/'}>{t('menu.home')}</Link>
              
              {/* sub menu */}
            </li>
            <li className="nav-item">
                  <Link className="nav-link" to={'/about'}>{t('menu.about')}</Link>                
            </li>
            <li className="nav-item">
                  <Link className="nav-link"  to={'/services'}>{t('menu.services')}</Link>
              <ul className="sub-menu">
                <li>
                  <Link to={'/service-software-develop'}>{t('services.software-develop.title')}</Link>
                </li>
                <li>
                  <Link to={'/service-web-develop'}>{t('services.web-develop.title')}</Link>
                </li>
                <li>
                  <Link to={'/service-seo'}>{t('services.seo.title')}</Link>
                </li>
                <li>
                  <Link to={'/service-ui-ux'}>{t('services.uiux.title')}</Link>
                </li>
                <li>
                  <Link to={'/service-digital-marketing'}>{t('services.digital.title')}</Link>
                </li>
                <li>
                  <Link to={'/service-it-resources'}>{t('services.it-resources.title')}</Link>
                </li>
              </ul>
              {/* sub menu */}
            </li>
            <li className="nav-item">
              <Link  className="nav-link" to={'/contact'}>{t('menu.contact')}</Link>
              
            </li>

          </Nav>
            </>
         );
    }
}
 
export default withTranslation('translation')(PartialNav);