import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

//import Index1 from './pages/home/index';
import Index2 from './pages/home/index-2';
//import Index3 from './pages/home/index-3';
//import Index4 from './pages/home/index-4';
import AboutPage from './pages/about';
import ContactPage from './pages/contact';
import ServicesPage from './pages/service';
import ServicesDetails from './pages/service/details';
import ServicesSoftwareDevelop from './pages/service/software-develop';
import ServicesSEO from './pages/service/seo';
import ServicesDigitalMarketing from './pages/service/digital-marketing';
import ServicesUIUX from './pages/service/ui-ux';
import ServicesWebDevelop from './pages/service/web-develop';
import ServicesITResources from './pages/service/it-resource';
import CaseStudy from './pages/case-study';
import CaseDetails from './pages/case-study/details';
import ComingSoon from './pages/coming-soon';
import FaqPage from './pages/faq';
import PricingPage from './pages/pricing';
import ProductPage from './pages/product';
import ProductDetailPage from "./pages/product-details";



import NoMatch from './pages/errors/no-match';
import Preloader from "./componets/preloader";

class App extends React.Component {

	render() {
		return (
			<BrowserRouter basename={'/'} >
				<Preloader/>
				<Routes>			
				  <Route exact path="" element={<Index2/>}/>				  

				  <Route path="about" element={<AboutPage/>}/>
				  <Route path="services" element={<ServicesPage/>}/>
				  <Route path="services-details" element={<ServicesDetails/>}/>
				  <Route path="service-software-develop" element={<ServicesSoftwareDevelop/>}/>
				  <Route path="service-web-develop" element={<ServicesWebDevelop/>}/>
				  <Route path="service-seo" element={<ServicesSEO/>}/>
				  <Route path="service-ui-ux" element={<ServicesUIUX/>}/>
				  <Route path="service-digital-marketing" element={<ServicesDigitalMarketing/>}/>
				  <Route path="service-it-resources" element={<ServicesITResources/>}/>

				  <Route path="case-study" element={<CaseStudy/>}/>
				  <Route path="case-details" element={<CaseDetails/>}/>
				  <Route path="coming-soon" element={<ComingSoon/>}/>
				  <Route path="faq" element={<FaqPage/>}/>
				  <Route path="pricing" element={<PricingPage/>}/>

				  <Route path="products" element={<ProductPage/>}/>
				  <Route path="products-details" element={<ProductDetailPage/>}/>
				  <Route path="contact" element={<ContactPage/>}/>

				  <Route element={<NoMatch/>} /> 
				 
				</Routes>
			<ToastContainer />
			
		</BrowserRouter>
		 );
	}
}
 
export default App;
