import React from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import AboutExperiencePart from '../../componets/aboutExperiencePart/Index';
import AboutIntroPart from '../../componets/aboutIntroPart/Index';
import AbotFaqPart from '../../componets/abotFaqPart/Index';
import { Container, Row, Col } from 'react-bootstrap';
import Layout from '../../layouts';

class AboutPage extends React.Component {

    render() { 
      const {t} = this.props
        return ( 
          <Layout>
          <div className="page-title-area">
            <Container>
              <Row>
                <Col lg="12">
                  <div className="page-title-item text-center">
                    <h2 className="title">{t("about-us")}</h2>
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <Link to={'index'}>{t("home")}</Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                          {t("about")}
                        </li>
                      </ol>
                    </nav>
                  </div>
                  {/* page title */}
                </Col>
              </Row>
              {/* row */}
            </Container>
            {/* container */}
          </div>
          {
            /*====== PAGE TITLE PART ENDS ======*/
          }
            <AboutExperiencePart/>
            <AboutIntroPart/>
            <AbotFaqPart/>
            </Layout>
         );
    }
}
 
export default withTranslation ('translation')(AboutPage);