import React from 'react';

import ModalVideo from 'react-modal-video'
import { Link } from 'react-router-dom';
import Layout from '../../layouts';
import { Container, Row, Col } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';

class ServicesSoftwareDevelop extends React.Component {
  constructor () {
    super()
    this.state = {
      isOpen: false
    }
    this.openModal = this.openModal.bind(this)
  }

  openModal = event  => {
    this.setState({isOpen: true})
    event.preventDefault()
  }
    render() { 
      const {t} = this.props
        return ( 
          <Layout>
            <div className="page-title-area">
              <Container>
                <Row>
                  <Col lg="12">
                    <div className="page-title-item text-center">
                      <h2 className="title">{t('services.software-develop.title')}</h2>
                      <nav aria-label="breadcrumb">

                      
                        <ol className="breadcrumb">
                          <li className="breadcrumb-item">
                            <Link  to={"/"}>{t('home')} </Link>
                          </li>
                          <li className="breadcrumb-item active" aria-current="page">
                            <Link  to={"/service"}>{t('services')} </Link>
                          </li>
                        </ol>
                      </nav>
                    </div>
                    {/* page title */}
                  </Col>
                </Row>
                {/* row */}
              </Container>
              {/* container */}
            </div>
            {
              /*====== PAGE TITLE PART ENDS ======*/
            }
            {
              /*====== PAGE Contents ======*/
            }
              <div className="about-intro-area pt-120">
                <Container>
                <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='7DAViuVx_rE' onClose={() => this.setState({isOpen: false})} />
                  <Row>
                    <Col lg="6">
                      <div className="intro-thumb">
                        <img src="/assets/images/about-intro-thumb.jpg" alt="" />
                        <Link className="video-popup" to="http://bit.ly/2VuPnrx" onClick={this.openModal}>
                          <i className="fas fa-play" />
                        </Link>
                      </div>
                    </Col>
                    <Col lg="6">
                      <div className="intri-content">
                        <span>{t("intro-video")}</span>
                        <h2 className="title">{t("how-we-growth")}<br />{t("our-business")}</h2>
                        <p className="text-1">{t("intri-content.text-1")}</p>
                        <p className="text-2">{t("intri-content.text-2")}</p>
                        <Link
                          className="main-btn wow fadeInUp"
                          to={"/"}
                          data-wow-duration="1s"
                          data-wow-delay=".4s"
                        >
                        {t("learn-more")}
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            </Layout>
         );
    }
}
 
export default withTranslation('translation')(ServicesSoftwareDevelop);