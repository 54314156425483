import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, Accordion } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';

class AnswersPart extends React.Component {

  render() { 
    const {t} = this.props
        return ( 
            <React.Fragment>
            {
              /*====== ANSWERS PART START ======*/
            }
            <div className="asnwers-area pt-105 pb-100 gray-bg">
              <Container>
                <Row>
                  <Col lg="6">
                    <div className="section-title-2">
                      <h2 className="title">{t("asnwers-title")}</h2>
                      <p>{t("asnwers-desc")}</p>
                    </div>
                    {/* section title 2 */}
                    <div className="faq-accordion-2 mt-30">
                      <Accordion defaultActiveKey="0">
                        <Card
                          className="wow fadeInLeft"
                          data-wow-duration=".5s"
                          data-wow-delay=".3s"
                        >
                          <Card.Header>
                            <Accordion.Toggle as={Link} eventKey="0">
                              {t("accordion-toggle1")}
                            </Accordion.Toggle>
                          </Card.Header>
                          <Accordion.Collapse eventKey="0">
                            <Card.Body>
                              <p>{t("accordion.collapse-card-desc")}</p>
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                        {/* card */}
                        <Card
                          className="wow fadeInLeft"
                          data-wow-duration=".6s"
                          data-wow-delay=".4s"
                        >
                          <Card.Header>
                            <Accordion.Toggle as={Link} eventKey="1">
                              {t("accordion-toggle2")}
                            </Accordion.Toggle>
                          </Card.Header>
                          <Accordion.Collapse eventKey="1">
                            <Card.Body>
                              <p>{t("accordion.collapse-card-desc-2")}</p>
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                        {/* card */}
                        <Card
                          className="wow fadeInLeft"
                          data-wow-duration=".7s"
                          data-wow-delay=".5s"
                        >
                          <Card.Header id="headingThree">
                            <Accordion.Toggle as={Link} eventKey="2">
                              {t("accordion-toggle3")}
                            </Accordion.Toggle>
                          </Card.Header>
                          <Accordion.Collapse eventKey="2">
                            <Card.Body>
                              <p>{t("accordion.collapse-card-desc-3")}</p>
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                        {/* card */}
                        <Card
                          className="wow fadeInLeft"
                          data-wow-duration=".8s"
                          data-wow-delay=".6s"
                        >
                          <Card.Header id="headingFour">
                            <Accordion.Toggle as={Link} eventKey="3">
                              {t("accordion-toggle4")}
                            </Accordion.Toggle>
                          </Card.Header>
                          <Accordion.Collapse eventKey="3">
                            <Card.Body>
                              <p>{t("accordion.collapse-card-desc-4")}</p>
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                        {/* card */}
                        <Card
                          className="wow fadeInLeft"
                          data-wow-duration=".9s"
                          data-wow-delay=".7s"
                        >
                          <Card.Header id="headingFive">
                            <Accordion.Toggle as={Link} eventKey="4">
                              {t("accordion-toggle5")}
                            </Accordion.Toggle>
                          </Card.Header>
                          <Accordion.Collapse eventKey="4">
                            <Card.Body>
                              <p>{t("accordion.collapse-card-desc-5")}</p>
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                        {/* card */}
                      </Accordion>
                    </div>
                    {/* faq accordion */}
                  </Col>
                  <Col lg="6">
                    <div className="answers-thumb">
                      <div className="answers-thumb-1 text-right">
                        <img src="assets/images/answers-thumb-1.jpg" alt="" />
                      </div>
                      <div className="answers-thumb-2">
                        <img src="assets/images/answers-thumb-2.jpg" alt="" />
                      </div>
                    </div>
                    {/* answers thumb */}
                  </Col>
                </Row>
                {/* row */}
              </Container>
              {/* containter */}
            </div>;
            {
              /*====== ANSWERS PART ENDS ======*/
            }
            </React.Fragment>
         );
    }
}
 
export default withTranslation ('translation') (AnswersPart);