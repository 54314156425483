import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';


class Footer extends React.Component {

  render() { 
    const {t} = this.props
      return ( 
        <React.Fragment>
        <footer className="footer-area footer-area-2 footer-area-1 bg_cover" style={{backgroundImage: 'url(assets/images/footer-bg.jpg)'}}>
          <div className="footer-overlay">
            <Container className="position-relative">
              <Row>
                <Col lg="4" md="7">
                  <div className="widget-item-1 mt-30">
                    <img src="/assets/images/logo-1.1.png" alt="" />
                    <p>{t('widget-item-1-p1')}</p>
                    <p>{t('widget-item-1-p2')}</p>
                  </div> {/* widget item 1 */}
                </Col>
                <Col lg="3" md="5" className="offset-lg-1">
                  <div className="widget-item-2 mt-30">
                    <h4 className="title">{t('footer-title')}</h4>
                    <div className="footer-list">
                      <ul>
                        <li><Link to={"/"}><i className="fal fa-angle-right" />{t('home')}</Link></li>
                        <li><Link to={"/services"}><i className="fal fa-angle-right" />{t('services')}</Link></li>
                        <li><Link to={"/about"}><i className="fal fa-angle-right" />{t('about')}</Link></li>
                        <li><Link to={"contact"}><i className="fal fa-angle-right" />{t('contact')}</Link></li>
                      </ul>
                     
                    </div>
                  </div> {/* widget item 2 */}
                </Col>
                <Col lg="4" md="6">
                  <div className="widget-item-2 widget-item-3 mt-30">
                    <h4 className="title">{t('working-hours')}</h4>
                    <ul>
                      <li>{t('monday-friday')}</li>
                      <li>{t('sunday-and-holidays')}</li>
                    </ul>
                    <p><span>{t('for-more-then-30-years')}</span>{t('for-more-then-30-years-ctn')}</p>
                    <Link  to={"#be"}><i className="fal fa-angle-right" />{t('discover-more')}</Link>
                  </div> {/* widget item 3 */}
                </Col>
              </Row> {/* row */}
              <Row>
                <Col lg="12">
                  <div className="footer-copyright">
                   
                    <p>Copyright By@ <span>BSON TECH</span> - {(new Date().getFullYear())}</p>
                  </div> {/* footer copyright */}
                </Col>
              </Row> {/* row */}
            </Container> {/* container */}
          </div>
        </footer>
        {/*====== BACK TO TOP ======*/}
        <div className="back-to-top back-to-top-2">
          <a  href="#backTotop">
            <i className="fas fa-arrow-up" />
          </a>
        </div>
        {/*====== BACK TO TOP ======*/}

      </React.Fragment>
    );
  }
}
 
export default withTranslation('translation')(Footer);