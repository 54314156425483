import React from 'react';
import { Link } from 'react-router-dom';
import './Index.scss';
import { Container, Row, Col } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';

class Index extends React.Component {

    render() { 
      const {t} = this.props
        return ( 
          <div className="about-experience-area pb-120">
            <Container>
              <Row>
                <Col lg="5">
                  <div className="experience-item">
                    <span>{t("experience-item.span-25-year")}</span>
                    <h2 className="title">{t("experience-item.title1")}</h2>
                  </div>
                  {/* experience item */}
                </Col>
                <Col lg="6" className="offset-lg-1">
                  <div className="experience-item">
                    <p>{t("experience-item.desc")}</p>
                    <Link to={"/about"}>{t("read-more")}</Link>
                  </div>
                  {/* experience item */}
                </Col>
              </Row>
              <Row className="justify-content-center">
                <div
                  className="col-lg-4 col-md-6 col-sm-8 wow fadeInUp"
                  data-wow-duration=".5s"
                  data-wow-delay=".2s"
                >
                  <div className="single-experience mt-30">
                    <img src="assets/images/experience-1.jpg" alt="" />
                    <div className="experience-overlay">
                      <h5 className="title">{t("experience-overlay.title1")}</h5>
                      <p>{t("experience-overlay-title.desc1")}</p>
                      <Link to={"about"}>{t("read-more")}</Link>
                      <i className="fal fa-laptop-code" />
                    </div>
                  </div>
                  {/* single experience */}
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-8 wow fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay=".4s"
                >
                  <div className="single-experience mt-30">
                    <img src="assets/images/experience-2.jpg" alt="" />
                    <div className="experience-overlay">
                      <h5 className="title">{t("experience-overlay.title2")}</h5>
                      <p>{t("experience-overlay-title.desc2")}</p>
                      <Link to="/home-1">{t("read-more")}</Link>
                      <i className="fal fa-headphones-alt" />
                    </div>
                  </div>
                  {/* single experience */}
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-8 wow fadeInUp"
                  data-wow-duration="1.5s"
                  data-wow-delay=".6s"
                >
                  <div className="single-experience mt-30">
                    <img src="assets/images/experience-3.jpg" alt="" />
                    <div className="experience-overlay">
                      <h5 className="title">{t("experience-overlay.title3")}</h5>
                      <p>{t("experience-overlay-title.desc3")}</p>
                      <Link to={"/about"}>{t("read-more")}</Link>
                      <i className="fal fa-analytics" />
                    </div>
                  </div>
                  {/* single experience */}
                </div>
              </Row>
              {/* row */}
            </Container>
            {/* container */}
          </div>
         );
    }
}
 
export default withTranslation ('translation') (Index);