import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLongArrowAltRight,
} from '@fortawesome/free-solid-svg-icons';
import { Container, Row, Col } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';

class LatestNewsPart extends React.Component {

    render() { 
      const {t} = this.props
        return ( 
          <div className="latest-news-area gray-bg">
            <Container>
              <Row className="justify-content-center">
                <Col lg="6">
                  <div className="section-title text-center">
                    <span>{t("latest-news-span")}</span>
                    <h2 className="title">{t("latest-news-title")}</h2>
                  </div>
                  {/* sction title */}
                </Col>
              </Row>
              {/* row */}
            </Container>
            {/* container */}
            <div className="letast-news-grid white-bg  ml-40 mr-40">
              <Container>
                <Row>
                  <Col lg="12">
                    <div className="latest-news">
                      <Row>
                        <div
                          className="col-lg-4 col-md-6 wow slideInUp"
                          data-wow-duration=".5s"
                          data-wow-delay="0s"
                        >
                          <div className="letest-news-item mt-30">
                            <span>{t("latest-news-item-span1")}</span>
                            <h4 className="title">
                              <Link to={"blog-details"}>
                                {t("latest-news-item-title1")}
                              </Link>
                            </h4>
                            <p>{t("latest-news-item-desc")}</p>
                            <Link to={"blog-details"} className="main-btn">
                              {t("read-more")} 
                              <FontAwesomeIcon icon={faLongArrowAltRight} className={`pl-1`} />
                            </Link>
                          </div>
                          {/* lesast news item */}
                        </div>
                        <div
                          className="col-lg-4 col-md-6 wow slideInUp"
                          data-wow-duration="1s"
                          data-wow-delay="0s"
                        >
                          <div className="letest-news-item mt-30">
                            <span>{t("latest-news-item-span2")}</span>
                            <h4 className="title">
                            <Link to={"blog-details"}> 
                              {t("latest-news-item-title2")}
                            </Link>
                            </h4>
                            <p>{t("latest-news-item-desc")}</p>
                            <Link to={"blog-details"} className="main-btn" >
                              {t("read-more")}
                              <FontAwesomeIcon icon={faLongArrowAltRight} className={`pl-1`} />
                            </Link>
                          </div>
                          {/* lesast news item */}
                        </div>
                        <div
                          className="col-lg-4 col-md-6 wow slideInUp"
                          data-wow-duration="1.5s"
                          data-wow-delay="0s"
                        >
                          <div className="letest-news-item mt-30">
                            <span>{t("latest-news-item-span3")}</span>
                            <h4 className="title">
                            <Link to={"blog-details"}>
                              {t("latest-news-item-title3")}
                              </Link>
                            </h4>
                            <p>{t("latest-news-item-desc")}</p>
                            <Link to={"blog-details"} className="main-btn" >
                              {t("read-more")}
                              <FontAwesomeIcon icon={faLongArrowAltRight} className={`pl-1`} />
                            </Link>
                          </div>
                          {/* lesast news item */}
                        </div>
                      </Row>
                    </div>
                    {/* latest news */}
                  </Col>
                </Row>
                {/* row */}
              </Container>
              {/* container */}
            </div>
          </div>
         );
    }
}
 
export default withTranslation ('translation') (LatestNewsPart);