import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';

class ServicesItemPart extends React.Component {

    render() { 
      const {t} = this.props
        return ( 
          <div className="services-item-area pt-90 pb-120">
            <Container>
              <Row className="justify-content-center">
                <Col lg="4" md="6" sm="8">
                  <div className="single-services-item mt-30">
                    <img src="assets/images/services-thumb-1.jpg" alt="" />
                    <div className="services-overlay">
                      <i className="fal fa-eye-dropper" />
                      <h4 className="title">{t("uiux-design")}</h4>
                      <p>{t("services-overlay.desc")}</p>
                    </div>
                  </div>
                  {/* single services */}
                </Col>
                <Col lg="4" md="6" sm="8">
                  <div className="single-services-item mt-30">
                    <img src="assets/images/services-thumb-2.jpg" alt="" />
                    <div className="services-overlay">
                      <i className="fal fa-gavel" />
                      <h4 className="title">{t("it-consultancy")}</h4>
                      <p>{t("services-overlay.desc")}</p>
                    </div>
                  </div>
                  {/* single services */}
                </Col>
                <Col lg="4" md="6" sm="8">
                  <div className="single-services-item mt-30">
                    <img src="assets/images/services-thumb-3.jpg" alt="" />
                    <div className="services-overlay">
                      <i className="fal fa-map-marked-alt" />
                      <h4 className="title">{t("technology-prof")}</h4>
                      <p>{t("services-overlay.desc")}</p>
                    </div>
                  </div>
                  {/* single services */}
                </Col>
                <Col lg="4" md="6" sm="8">
                  <div className="single-services-item mt-30">
                    <img src="assets/images/services-thumb-5.jpg" alt="" />
                    <div className="services-overlay">
                      <i className="fal fa-hurricane" />
                      <h4 className="title">{t("web-development")}</h4>
                      <p>{t("services-overlay.desc")}</p>
                    </div>
                  </div>
                  {/* single services */}
                </Col>
                <Col lg="4" md="6" sm="8">
                  <div className="single-services-item mt-30">
                    <img src="assets/images/services-thumb-4.jpg" alt="" />
                    <div className="services-overlay">
                      <i className="fal fa-bezier-curve" />
                      <h4 className="title">{t("app-development")}</h4>
                      <p>{t("services-overlay.desc")}</p>
                    </div>
                  </div>
                  {/* single services */}
                </Col>
                <Col lg="4" md="6" sm="8">
                  <div className="single-services-item mt-30">
                    <img src="assets/images/services-thumb-6.jpg" alt="" />
                    <div className="services-overlay">
                      <i className="fal fa-umbrella" />
                      <h4 className="title">{t("game-design")}</h4>
                      <p>{t("services-overlay.desc")}</p>
                    </div>
                  </div>
                  {/* single services */}
                </Col>
              </Row>
              {/* row */}
            </Container>
            {/* container */}
          </div>
         );
    }
}
 
export default withTranslation ('translation') (ServicesItemPart);