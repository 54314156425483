import i18n from "i18next";

import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from "react-i18next";

import translationEN from './locales/translations/en.json';
import translationVI from './locales/translations/vi.json';

const resources = {
    en: {
      translation: translationEN
    },
    vi: {
      translation: translationVI
    }
  };

const defaultLang = localStorage.getItem('lang') || "en"

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: defaultLang,   //default language
    fallbackLng: "en", //when specified language translations not present 
//then fallbacklang translations loaded.
    debug: true,

/* can have multiple namespace, in case you want to divide a huge translation into smaller pieces and load them on demand */
    ns: ["translations"],
    defaultNS: "translations",
    keySeparator: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ",",
    },
    react: {
        useSuspense: false
    },
  });


export default i18n;