import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import axios from 'axios';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { I18nextProvider } from 'react-i18next';

import i18n from './i18n';
//axios.defaults.baseURL = 'hhttp://localhost:3000/api/';
//axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
document.documentElement.lang = i18n.language;
i18n.init().then(
    () => ReactDOM.render(<App />, document.getElementById('root'))
);
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
