import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
} from '@fortawesome/free-solid-svg-icons';
import {faLongArrowAltRight} from "@fortawesome/free-solid-svg-icons";
import { Container, Row, Col } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';

class WhatWeDoPart extends React.Component {

    render() { 
      const { t } = this.props
        return ( 
          <div className="what-we-do-area gray-bg pt-105 pb-120">
            <Container>
              <Row>
                <div className="col-lg-5 col-md-7">
                  <div className="section-title-2">
                    <h2 className="title">{t('what-we-do')}</h2>
                    <p>
                    {t('what-we-do.desc')}
                    </p>
                  </div>
                  {/* section title 2 */}
                </div>
              </Row>
              <Row>
                <Col lg="4" md="6">
                  <div className="what-we-do-item mt-30">
                    <Link  to={"service-software-develop"}>
                      <h6>{t('what-we-do-part.software-dev')}</h6>
                      <FontAwesomeIcon icon={faLongArrowAltRight} />
                    </Link>
                  </div>
                  {/* what we do item */}
                  
                </Col>
                <Col lg="4" md="6">
                  <div className="what-we-do-item mt-30">
                    <Link  to={"service-web-develop"}>
                      <h6>{t('what-we-do-part.web-dev')} </h6>
                      <FontAwesomeIcon icon={faLongArrowAltRight} />
                    </Link>
                  </div>
                  {/* what we do item */}
                </Col>
                <Col lg="4" md="6">
                  <div className="what-we-do-item mt-30">
                    <Link  to={"service-seo"}>
                      <h6>{t('what-we-do-part.seo-service')}</h6>
                      <FontAwesomeIcon icon={faLongArrowAltRight} />
                    </Link>
                  </div>
                  {/* what we do item */}
                </Col>
                <Col lg="4" md="6">
                  <div className="what-we-do-item mt-30">
                    <Link  to={"service-ui-ux"}>
                      <h6>{t('what-we-do-part.ui-ux-design')}</h6>
                      <FontAwesomeIcon icon={faLongArrowAltRight} />
                    </Link>
                  </div>
                  {/* what we do item */}
                </Col>
                <Col lg="4" md="6">
                  <div className="what-we-do-item mt-30">
                    <Link  to={"service-digital-marketing"}>
                      <h6>{t('services.digital.title')}</h6>
                      <FontAwesomeIcon icon={faLongArrowAltRight} />
                    </Link>
                  </div>
                  {/* what we do item */}
                </Col>
                
                <Col lg="4" md="6">
                  <div className="what-we-do-item mt-30">
                    <Link  to={"service-it-resources"}>
                      <h6>{t('services.it-resources.title')}</h6>
                      <FontAwesomeIcon icon={faLongArrowAltRight} />
                    </Link>
                  </div>
                  {/* what we do item */}
                </Col>
                
              </Row>
              {/* row */}
            </Container>
            {/* container */}
          </div>
         );
    }
}
 
export default withTranslation('translation')(WhatWeDoPart);